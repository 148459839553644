import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { fetchChats, sendChat } from '../../api/BandChats/chats'

export const useFetchChat = (bandId) => {
  return useQuery(['chats', bandId], fetchChats, {
    // onSuccess,
    // onError,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 60000,
  })
}

export const useSendChat = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation(
    ['send-chat'],
    (value) => sendChat(value),
    {
      onMutate: async (newData) => {
        await queryClient.cancelQueries(['chats', newData?.bandId])

        const previousData = queryClient.getQueryData([
          'chats',
          newData?.bandId,
        ])

        queryClient.setQueryData(
          ['chats', newData?.bandId],
          [
            ...previousData,
            {
              id: previousData?.length + 1,
              message: newData?.message,
              bandId: newData?.bandId,
              memberId: newData?.user?.id,
              sender: {
                userId: newData?.user?.id,
                nickname: newData?.user?.nickname,
              },
              createdAt: new Date(Date.now()).toISOString(),
              updatedAt: new Date(Date.now()).toISOString(),
            },
          ],
        )

        return { previousData }
      },
      onSuccess: (data, variables, context) => {
        onSuccess && onSuccess(data, variables, context)
      },
      onError: (err, _variables, _context) => {
        queryClient.setQueryData(
          ['chats', _variables.bandId],
          _context.previousData,
        )

        console.log(
          '🚀 ~ useSendChat ~ err, _variables, _context:',
          err,
          _variables,
          _context,
        )
        toast.error('Error sending chat')
      },
    },
    {
      refetchOnWindowFocus: 'always',
      refetchInterval: false,
      refetchOnMount: true,
      cacheTime: 0,
      staleTime: 60000,
    },
  )
}
