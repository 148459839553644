import { useMemo } from 'react'
import io from 'socket.io-client'
import { getTokens } from '../../utils/cookieHelper'

export const useSocket = (userId, bandId) => {
  const { accessToken } = getTokens()

  const socket = useMemo(
    () =>
      io(process.env.REACT_APP_BASE_URL, {
        auth: {
          token: accessToken,
        },
        query: {
          userId,
          bandId,
        },
        transports: ['websocket'],
      }),
    [userId, bandId],
  )

  return socket
}
