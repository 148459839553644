import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createSetList,
  deleteSetList,
  deleteSongFromSetList,
  duplicateSetList,
  fetchSetList,
  fetchSetListById,
  orderSong,
} from '../../api/BandSongs/set-lists'
import { toast } from 'react-toastify'

export const useSetList = (bandId) => {
  return useQuery(['set-lists', bandId], fetchSetList, {
    // onSuccess,
    // onError,
  })
}

export const useCreateSetlist = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation(
    ['createSetlist'],
    (value) => createSetList(value),
    {
      onSuccess: (data, variables, context) => {
        toast.success('Added Setlist Successfully')
        queryClient.invalidateQueries(['set-lists'])
        onSuccess && onSuccess(data, variables, context)
      },
      onError: (err, _variables, _context) => {
        console.log(
          '🚀 ~ file: useSetLists.js:29 ~ useCreateSetlist ~ err, _variables, _context:',
          err,
          _variables,
          _context,
        )
        toast.error('Error adding setlist')
      },
    },
    {
      refetchOnWindowFocus: 'always',
      refetchInterval: false,
      refetchOnMount: true,
      cacheTime: 0,
      staleTime: 60000,
    },
  )
}

export const useDeleteSongFromSetlist = (query, onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation(
    ['deleteSong', query],
    (values) => {
      return deleteSongFromSetList(values)
    },
    {
      onSuccess: (data, variables, context) => {
        toast.success('Song Deleted Successfully')
        queryClient.invalidateQueries(['set-lists'])
        if (query) queryClient.invalidateQueries([`set-lists${query.bandId}`])
        onSuccess && onSuccess(data, variables, context)
      },
      onError: (err, _variables, _context) => {
        console.log(
          '🚀 ~ file: useSetLists.js:29 ~ useDeleteSongFromSetlist ~ err:',
          err,
          _variables,
          _context,
        )
        toast.error('Failed to delete Song')
      },
    },
  )
}
export const useDeleteSetlist = (setlistId, onSuccess) => {
  const queryClient = useQueryClient()

  return useMutation(
    ['deleteSetlist', setlistId],
    (values) => {
      return deleteSetList(values)
    },
    {
      onSuccess: (data, variables, context) => {
        toast.success('Setlist Deleted Successfully')
        queryClient.invalidateQueries(['set-lists'])
        onSuccess && onSuccess(data, variables, context)
      },
      onError: (err, _variables, _context) => {
        console.log(
          '🚀 ~ file: useSetLists.js:29 ~ useDeleteSetlist ~ err:',
          err,
          _variables,
          _context,
        )
        toast.error('Failed to delete Setlist')
      },
    },
  )
}
export const useDuplicateSetlist = (setlistId, onSuccess) => {
  const queryClient = useQueryClient()

  return useMutation(
    ['duplicateSetlist', setlistId],
    (values) => {
      return duplicateSetList(values)
    },
    {
      onSuccess: (data, variables, context) => {
        toast.success('Setlist duplicated Successfully')
        queryClient.invalidateQueries(['set-lists'])
        onSuccess && onSuccess(data, variables, context)
      },
      onError: (err, _variables, _context) => {
        console.log(
          '🚀 ~ file: useSetLists.js:87 ~ useDuplicateSetlist ~ err:',
          err,
          _variables,
          _context,
        )
        toast.error('Failed to duplicate Setlist')
      },
    },
  )
}

export const useSetListID = (bandId, setListId) => {
  return useQuery(
    [`set-lists${bandId}`, bandId, setListId],
    fetchSetListById,
    {},
  )
}

export const useOrderSong = (setlistId, onSuccess) => {
  const queryClient = useQueryClient()

  return useMutation(
    ['orderSong', setlistId],
    (values) => {
      return orderSong(values)
    },
    {
      onMutate: async (newData) => {
        await queryClient.cancelQueries([
          `set-lists${newData.bandId}`,
          newData.bandId,
          newData.setListId,
        ])

        const previousData = queryClient.getQueryData([
          `set-lists${newData.bandId}`,
          newData.bandId,
          newData.setListId,
        ])

        queryClient.setQueryData(
          [`set-lists${newData.bandId}`, newData.bandId, newData.setListId],
          {
            ...previousData,
            songs: newData.newOrderList,
          },
        )

        return { previousData }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['set-lists'])
        queryClient.invalidateQueries([`set-lists${variables.bandId}`])
        onSuccess && onSuccess(data, variables, context)
      },
      onError: (err, _variables, _context) => {
        queryClient.setQueryData(
          [
            `set-lists${_variables.bandId}`,
            _variables.bandId,
            _variables.setListId,
          ],
          _context.previousData,
        )

        console.log(
          '🚀 ~ file: useSetLists.js:87 ~ useOrderSong ~ err:',
          err,
          _variables,
          _context,
        )
        toast.error('Failed to order Setlist')
      },
    },
  )
}
