/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {
  AiFillDelete,
  AiFillFileText,
  AiOutlineDownload,
  AiOutlineFileAdd,
  AiOutlinePlus,
} from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

import { RiAddLine, RiFileCopy2Fill } from 'react-icons/ri'
import { getSelectedBand, getUser } from '../../utils/cookieHelper'
import { useSongsList } from '../../hooks/songs/useSongs'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { toast } from 'react-toastify'
import SongDropAble from '../../components/DragAndDrop/SongDropAble'
import Button from '../../components/Button'
import SongDragAble from '../../components/DragAndDrop/SongDragAble'
import {
  useSetList,
  useDuplicateSetlist,
  useDeleteSetlist,
  useOrderSong,
} from '../../hooks/setLists/useSetLists'
import { useMutation } from '@tanstack/react-query'
import { addSongToSetList } from '../../api/BandSongs/set-lists'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import {
  findContainer,
  findPlaylistIndex,
  findPlaylistIndexWithId,
} from '../../utils/sortable'
import { PDFDownloadLink } from '@react-pdf/renderer'
import PdfContent from '../../components/DragAndDrop/PdfContent.jsx'
import { useFetchBand } from '../../hooks/bands/useBands.js'

export const SetLists = () => {
  const { band } = getSelectedBand()
  const bandId = band?.id

  const { data: bandData, isUserBandLoading } = useFetchBand()

  const { data: songList, isLoading } = useSongsList(bandId)
  const { data: setList, isLoading: isSetListLoading } = useSetList(bandId)

  const navigate = useNavigate()
  const [isDragging, setIsDragging] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [playlists, setPlaylists] = useState([])
  const duplicatedSetList = useDuplicateSetlist()
  const handleDuplicate = (bandId, playlistId) => {
    duplicatedSetList.mutate({ bandId, playlistId })
  }
  const handleDeleteSetlist = (playlistId) => {
    deleteSetlist.mutate({ bandId: bandId, playlistId })
  }

  useEffect(() => {
    if (setList) {
      setPlaylists(setList || [])
    }
  }, [setList])
  const deleteSetlist = useDeleteSetlist()
  const useOrderSongHook = useOrderSong()

  const handleNewSetList = () => {
    navigate('/add-setlist')
  }

  const handleSetlist = (state) => {
    navigate('songlist', { state: { setlist: state } })
  }

  const handleAddSong = () => {
    navigate('/add-song')
  }

  // Define the mutation function
  const addSong = useMutation((values) => addSongToSetList(values), {
    onSuccess: () => {
      toast.success('Song added successfully')
    },
    onError: () => {
      toast.error('Not able to add song')
    },
  })

  const updateIsMobile = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateIsMobile)

    updateIsMobile()

    return () => {
      window.removeEventListener('resize', updateIsMobile)
    }
  }, [])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleOnDragStart = () => {
    setIsDragging(true)
  }

  const handleOnDragEnd = async ({ active, over }) => {
    if (!active && !over) {
      return
    }
    setIsDragging(false)
    if (active.data.current.sortable !== undefined) {
      const { id: activeId = {} } = active || {}
      const { id: overId } = over || {}

      const activeContainer = await findContainer(playlists, activeId)
      const overContainer = await findContainer(playlists, overId)

      // Check if the drag is within the same container
      if (activeContainer === overContainer) {
        const playlistIndex = findPlaylistIndex(playlists, overContainer)

        if (playlistIndex !== -1) {
          const updatedPlaylists = [...playlists]
          const activeIndex = updatedPlaylists[playlistIndex].songs.findIndex(
            (song) => song.songId === activeId,
          )

          const overIndex = updatedPlaylists[playlistIndex].songs.findIndex(
            (song) => song.songId === overId,
          )

          // Swap the songs' positions
          ;[
            updatedPlaylists[playlistIndex].songs[activeIndex],
            updatedPlaylists[playlistIndex].songs[overIndex],
          ] = [
            updatedPlaylists[playlistIndex].songs[overIndex],
            updatedPlaylists[playlistIndex].songs[activeIndex],
          ]

          useOrderSongHook.mutate({
            bandId: band.id,
            setListId: updatedPlaylists[playlistIndex].id,
            newOrderList: updatedPlaylists?.[playlistIndex]?.songs,
          })
          setPlaylists(updatedPlaylists)
        }
      }
    } else {
      const { id: activeId, title } = active.data.current

      if (!over) return
      const { id: overId } = over
      const { playlistId } = over.data.current

      const playlistIndex = findPlaylistIndexWithId(playlists, playlistId)

      if (playlistIndex !== -1) {
        const isSongInPlaylist = playlists[playlistIndex].songs.some(
          (song) => song.id === activeId,
        )
        if (!isSongInPlaylist) {
          const updatedPlaylists = [...playlists]
          const addSongData = {
            id: activeId,
            title,
            songId: `${title
              .toLowerCase()
              .replace(/\s/g, '_')}_${playlistId}_${activeId}`,
          }

          updatedPlaylists[playlistIndex].songs.push(addSongData)

          setPlaylists(updatedPlaylists)

          addSong.mutate({
            songId: activeId,
            bandId: band.id,
            setListId: playlistId,
          })
        } else {
          toast.info('Song already added!')
        }
      }
    }
  }

  if (isLoading || isSetListLoading || isUserBandLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    )
  }
  return (
    <div className="flex flex-col p-3 lg:px-0">
      <div className="flex justify-between">
        <div className=" text-16 font-semibold">Manage Setlists</div>
        <div className="flex gap-2 text-20">
          <AiOutlineFileAdd onClick={handleNewSetList} />
          {/* <AiOutlineSearch /> <AiOutlineAppstore /> <AiOutlineDatabase /> */}
        </div>
      </div>
      <div>
        {isMobile ? (
          <div className="my-4">
            <ul className="flex flex-col list-none p-0 gap-2 overflow-auto">
              {playlists &&
                playlists?.map((setListObj) => (
                  <li
                    key={setListObj.setListId}
                    className="flex items-center justify-between p-2 bg-main-bg-dark text-white"
                    onClick={() => handleSetlist(setListObj)}
                  >
                    <div className="flex gap-2 items-center">
                      <AiFillFileText />
                      <p>{setListObj.title}</p>
                    </div>
                    <div className="flex gap-2 mt-1">
                      <PDFDownloadLink
                        onClick={(e) => e.stopPropagation()}
                        document={
                          <PdfContent
                            title={setListObj.title}
                            songs={setListObj.songs}
                          />
                        }
                        fileName="setlist.pdf"
                      >
                        <AiOutlineDownload />
                      </PDFDownloadLink>
                      <RiFileCopy2Fill
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDuplicate(setListObj.bandId, setListObj.id)
                        }}
                      ></RiFileCopy2Fill>
                      <AiFillDelete
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDeleteSetlist(setListObj.id)
                        }}
                      />
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        ) : (
          <div>
            <DndContext
              onDragStart={handleOnDragStart}
              onDragEnd={handleOnDragEnd}
              sensors={sensors}
            >
              <div className="flex gap-4 justify-between">
                <div className="my-4">
                  <ul className="flex flex-wrap list-none gap-2">
                    {playlists &&
                      playlists?.map(
                        ({ id, setListId, title, songs, bandId }) => (
                          <SongDropAble
                            key={setListId}
                            id={setListId}
                            playlistId={id}
                            bandId={bandId}
                            title={title}
                            songs={songs}
                            isMobile={false}
                          />
                        ),
                      )}

                    <li
                      className="setlist flex flex-col flex-grow items-center justify-center p-2 border-dashed border-1 border-gray-600 text-gray-600 w-1/5 h-60 max-w-[290px] cursor-pointer hover:shadow-2xl overflow-x-hidden"
                      onClick={handleNewSetList}
                    >
                      <RiAddLine className="text-3xl" /> Add New Set List
                    </li>
                  </ul>
                </div>
                <div className="songlist my-4">
                  <div
                    className={`flex flex-col justify-self-end  bg-main-bg-dark p-2 ${
                      isDragging
                        ? ''
                        : ' h-full overflow-y-auto overflow-x-hidden'
                    }`}
                  >
                    <div className="flex justify-between items-center cursor-pointer">
                      <p className=" text-18 font-semibold text-white p-2">
                        Songs List
                      </p>
                      <div
                        className="flex flex-row items-center gap-1 text-20 text-white"
                        onClick={handleAddSong}
                      >
                        <p className="text-14 font-semibold text-white p-2">
                          Add Song
                        </p>
                        <AiOutlinePlus />
                      </div>
                    </div>

                    <SortableContext
                      id={'songlist'}
                      strategy={verticalListSortingStrategy}
                      items={songList?.data}
                    >
                      <ul className="flex flex-col flex-grow list-none gap-1 ">
                        {songList?.data?.map(({ id, title }) => (
                          <SongDragAble
                            key={id}
                            id={id}
                            title={title}
                            bandId={bandId}
                          />
                        ))}
                      </ul>
                    </SortableContext>
                    <Button
                      bgColor="bg-main-bg-pink"
                      text="Add Song"
                      size="md"
                      borderRadius="0px"
                      classes={'w-full mt-5'}
                      onClick={handleAddSong}
                    />
                  </div>
                </div>
              </div>
            </DndContext>
          </div>
        )}
      </div>
    </div>
  )
}
export const SetListRenderer = () => {
  const { isLoading: isUserBandLoading } = useFetchBand()
  if (isUserBandLoading)
    return (
      <div className="flex  items-center justify-center h-screen">
        Loading...
      </div>
    )
  return <SetLists />
}
